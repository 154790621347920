/* red-hat-display-400normal - latin-ext */
@font-face {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Red Hat Display'),
    local('RedHatDisplay-Regular'), 
    url('./files/red-hat-display-latin-ext-400.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/red-hat-display-latin-ext-400.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* red-hat-display-400italic - latin-ext */
@font-face {
  font-family: 'Red Hat Display';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Red Hat Display It'),
    local('RedHatDisplay-Italic'), 
    url('./files/red-hat-display-latin-ext-400-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/red-hat-display-latin-ext-400-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* red-hat-display-500normal - latin-ext */
@font-face {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Red Hat Display Medium'),
    local('RedHatDisplay-Medium'), 
    url('./files/red-hat-display-latin-ext-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/red-hat-display-latin-ext-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* red-hat-display-500italic - latin-ext */
@font-face {
  font-family: 'Red Hat Display';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Red Hat Display Medium It'),
    local('RedHatDisplay-MediumItalic'), 
    url('./files/red-hat-display-latin-ext-500-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/red-hat-display-latin-ext-500-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* red-hat-display-700normal - latin-ext */
@font-face {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Red Hat Display Bold'),
    local('RedHatDisplay-Bold'), 
    url('./files/red-hat-display-latin-ext-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/red-hat-display-latin-ext-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* red-hat-display-700italic - latin-ext */
@font-face {
  font-family: 'Red Hat Display';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Red Hat Display Bold It'),
    local('RedHatDisplay-BoldItalic'), 
    url('./files/red-hat-display-latin-ext-700-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/red-hat-display-latin-ext-700-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* red-hat-display-900normal - latin-ext */
@font-face {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Red Hat Display Black'),
    local('RedHatDisplay-Black'), 
    url('./files/red-hat-display-latin-ext-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/red-hat-display-latin-ext-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* red-hat-display-900italic - latin-ext */
@font-face {
  font-family: 'Red Hat Display';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Red Hat Display Black It'),
    local('RedHatDisplay-BlackItalic'), 
    url('./files/red-hat-display-latin-ext-900-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/red-hat-display-latin-ext-900-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
